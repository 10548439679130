import {get} from 'lodash'
import React, {ReactNode, useCallback, useState} from 'react'
import {useFormContext} from 'react-hook-form'
import {
  FieldError,
  NestDataObject,
  ValidationOptions,
} from 'react-hook-form/dist/types'
import InputMask, {InputState} from 'react-input-mask'
import {FormFeedback, FormGroup, Input, Label} from 'reactstrap'
import {FormSocialInput} from './social-input'

// Disable as the helper functions below are not actually components
/* eslint-disable react/no-multi-comp */

/** Input type. */
export type InputType = import('reactstrap/lib/Input').InputType

interface BaseProps extends ValidationOptions {
  protect?: boolean
  label?: ReactNode
  inputMaxLength?: number
  inputMinLength?: number
  name: string
  type: InputType | 'social'
  children?: undefined
  placeholder?: string
  defaultValue?: string | string[]
  mask?: undefined
  onChange?(): void
}

interface PropsWithSelect extends Omit<BaseProps, 'children'> {
  type: 'select'
  children: ReactNode | ReactNode[]
}

interface PropsWithCheckbox extends Omit<BaseProps, 'onChange'> {
  type: 'checkbox'
  onChange?: undefined
}

interface PropsWithMask extends Omit<BaseProps, 'mask'> {
  mask: FormItemMask
}

type Props = BaseProps | PropsWithSelect | PropsWithMask | PropsWithCheckbox

interface MaskChangeEvent {
  currentState: InputState
  nextState: InputState
}

const createErrorMessage = (
  error?: FieldError | NestDataObject<unknown> | NestDataObject<unknown>[],
) => {
  if (!error || !('type' in error)) {
    return undefined
  }
  const message = error.message === '' ? 'Field Required' : error.message
  return <FormFeedback>{message}</FormFeedback>
}

const createLabelMessage = (
  name: string,
  label?: ReactNode,
  required?: ValidationOptions['required'],
) => {
  if (label === undefined) {
    return undefined
  }
  const message =
    required !== undefined && typeof label === 'string' ? <>{label}*</> : label
  return (
    <small>
      <Label htmlFor={name}>{message}</Label>
    </small>
  )
}

/**
 * Factory function to handle checkbox change.
 * @return Callback
 */
// FIXME Remove manual for checkbox as it currently does not work in iOS
export const createCheckboxOnChange = ({name, setValue}: Options) => (
  e: Event,
) => {
  setValue(name, e.target.checked, true)
}

/** Form item mask. */
export interface FormItemMask {
  mask: string
  alwaysShowMask?: boolean
  maskPlaceholder?: string | null
  beforeMaskedStateChange?(event: MaskChangeEvent): InputState
}

/**
 * Render form items.
 * @return React component
 */
export const FormItem = ({
  children,
  defaultValue,
  label,
  protect,
  name,
  type,
  inputMaxLength,
  mask,
  placeholder,
  onChange,
  ...options
}: Props) => {
  const {errors, register, formState, setValue} = useFormContext()
  const error = get(errors, name)
  const isCheckbox = type === 'checkbox'
  const isSocial = type === 'social'
  const ref = register(options)
  const [hidden, setHidden] = useState(true)
  const toggleSocialButton = useCallback(() => {
    setHidden(!hidden)
  }, [hidden, setHidden])
  const updateCheckbox = useCallback(
    createCheckboxOnChange({name, setValue}),
    [name, setValue],
  )
  const errorMessage = createErrorMessage(error)
  const labelMessage = createLabelMessage(name, label, options.required)
  const handleChange = (e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = e.target as HTMLInputElement | HTMLTextAreaElement
    if (target && (target.name === "responseNatureOfFeedbackToReport")) {
      return;
    }
    e.preventDefault();
  };
  let input = (
    <Input
      autoComplete={type === 'social' ? 'off' : 'on'}
      className={protect ? 'protected' : undefined}
      defaultValue={defaultValue}
      id={name}
      innerRef={ref}
      invalid={Boolean(error)}
      maxLength={inputMaxLength}
      name={name}
      placeholder={placeholder}
      type={type === 'social' ? 'tel' : type}
      valid={!error && (get(formState.touched, name) as boolean | undefined)}
      onChange={isCheckbox ? updateCheckbox : onChange}
      onCut={handleChange}
      onCopy={handleChange}
      onPaste={handleChange}
    >
      {children}
    </Input>
  )
  if (mask) {
    input = <InputMask {...mask}>{input}</InputMask>
  }
  if (isSocial) {
    input = (
      <FormSocialInput
        errorMessage={errorMessage}
        hidden={hidden}
        onClick={toggleSocialButton}
      >
        {input}
      </FormSocialInput>
    )
  }
  return (
    <FormGroup check={isCheckbox}>
      {isCheckbox ? input : labelMessage}
      {isCheckbox ? labelMessage : input}
      {errorMessage}
    </FormGroup>
  )
}

interface Options {
  name: string
  setValue: Function
}

interface Event {
  target: {
    checked: boolean
  }
}
